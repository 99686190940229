/**
 * @fileOverview This file is part of a web application developed by Mattes Knigge.
 * @author Mattes Knigge
 * @version 1.0.0
 *
 * Description: This JavaScript file is responsible for implementing the core functionalities of the web application,
 * including event handling, data processing, and interacting with other web APIs.
 * It encapsulates the necessary logic to ensure smooth operation and user interaction within the application, contributing to the overall user experience and application performance.
 *
 * Created Date: 2024
 * Modified Date: 2024 - if applicable
 *
 * Contact Information:
 *   Name: Mattes Knigge
 *   Email: ma.knigge@ostfalia.de
 *
 * © 2024 Mattes Knigge.
 */

import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import './styles/index.css';

function App() {
    return (
        <div className="App">
            <Header logo="logo-svg.svg" />
            <main>
                <Home />
            </main>
            <Footer />
        </div>
    );
}

export default App;
