/**
 * @fileOverview This file is part of a web application developed by Mattes Knigge.
 * @author Mattes Knigge
 * @version 1.0.0
 *
 * Description: This JavaScript file is responsible for implementing the core functionalities of the web application,
 * including event handling, data processing, and interacting with other web APIs.
 * It encapsulates the necessary logic to ensure smooth operation and user interaction within the application, contributing to the overall user experience and application performance.
 *
 * Created Date: 2024
 * Modified Date: 2024 - if applicable
 *
 * Contact Information:
 *   Name: Mattes Knigge
 *   Email: ma.knigge@ostfalia.de
 *
 * © 2024 Mattes Knigge.
 */

import React, { useState } from 'react';
import '../styles/Footer.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function Footer() {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <footer className="footer">
            <div className="footer-info">
                <p>ForestGuardian - Safeguarding the wilderness with data.</p>
                <div className="footer-legal">
                    <p>Public Domain. No rights reserved.</p>
                    <p>Designed by Mattes Knigge</p>
                    <Button style={{color: "#E8E4D6", fontSize:"0.4rem"}} variant="text" onClick={handleClickOpen}>
                        Impressum
                    </Button>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle fontFamily={"Bebas Neue"} className="dialog-title">Imprint</DialogTitle>
                        <DialogContent className="info-dialog-content">
                            <DialogContentText fontFamily={"Montserrat"} fontSize={"1rem"} style={{ textAlign: 'center' }}>
                                This imprint applies to all offers under the domain forestguardian.de, including all subdomains.
                                <br/>
                                Information according § 5 TMG:
                                <br/><br/>
                                Mattes Knigge
                                <br/>
                                Bahnhofstraße 40
                                <br/>
                                38368 Grasleben
                                <br/>
                                Germany
                                <br/>
                                Email: <a href="mailto:ma.knigge@ostfalia.de">ma.knigge@ostfalia.de</a>
                                <br/><br/>
                                Disclaimer: Despite careful content control, I assume no liability for the content of external links. The operators of the linked pages are solely responsible for their content.
                                <br/><br/>
                                This is a personal project by Mattes Knigge and is not associated with any commercial activities.
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
