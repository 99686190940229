/**
 * @fileOverview This file is part of a web application developed by Mattes Knigge.
 * @author Mattes Knigge
 * @version 1.0.0
 *
 * Description: This JavaScript file is responsible for implementing the core functionalities of the web application,
 * including event handling, data processing, and interacting with other web APIs.
 * It encapsulates the necessary logic to ensure smooth operation and user interaction within the application, contributing to the overall user experience and application performance.
 *
 * Created Date: 2024
 * Modified Date: 2024 - if applicable
 *
 * Contact Information:
 *   Name: Mattes Knigge
 *   Email: ma.knigge@ostfalia.de
 *
 * © 2024 Mattes Knigge.
 */

import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import TeamIcon from '@mui/icons-material/Group';
import GitHubIcon from '@mui/icons-material/GitHub';
import ContactMailIcon from '@mui/icons-material/ContactMail'; // Icon for the contact button
import '../styles/Header.css'; // Ensure this is your header CSS file

function Header({ logo }) {
    const [open, setOpen] = useState(false);
    const [contactOpen, setContactOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleContactClick = () => {
        setContactOpen(true);
    };

    const handleContactClose = () => {
        setContactOpen(false);
    };

    const handleGitClick = () => {
        window.open('https://gitlab.com/whosmattes/sensorknoten-vogelhaus', '_blank');
    };

    return (
        <header className="new-header">
            <img src={logo} alt="Logo" className="logo" />
            <div className="button-container">
                <IconButton onClick={handleClickOpen} aria-label="team" className="team-button">
                    <TeamIcon />
                </IconButton>
                <IconButton onClick={handleGitClick} className="git-button">
                    <GitHubIcon />
                </IconButton>
                <IconButton onClick={handleContactClick} className="contact-button">
                    <ContactMailIcon />
                </IconButton>
            </div>
            {/* Team Information Dialog */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle fontFamily={"Bebas Neue"} className="dialog-title">Unser Team</DialogTitle>
                <DialogContent className="info-dialog-content">
                    <DialogContentText fontFamily={"Montserrat"} fontSize={"1rem"} style={{ textAlign: 'center' }}>
                        <div>
                            <DialogContentText fontFamily={"Montserrat"} fontSize={"1rem"} style={{ textAlign: 'center' }}>
                                <p>Jannes Bikker <br/> Backend-Entwickler</p>
                                <p>Niklas Lugowski <br/> Hardware-Entwickler</p>
                                <p>Mattes Knigge <br/> Frontend-Entwickler</p>
                            </DialogContentText>
                        </div>
                        <br/>
                        <p>Unser Team bringt Fachkenntnisse und Hingabe zusammen, um zum Wohlbefinden unserer Wälder beizutragen. Wir bemühen uns kontinuierlich um neue Wege, die Natur zu erhalten und zu schützen.</p>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            {/* Contact Information Dialog */}
            <Dialog open={contactOpen} onClose={handleContactClose}>
                <DialogTitle fontFamily={"Bebas Neue"} className="dialog-title">Kontakt</DialogTitle>
                <DialogContent className="info-dialog-content">
                    <DialogContentText fontFamily={"Montserrat"} fontSize={"1rem"} style={{ textAlign: 'center' }}>
                        <p>Wir sind offen für Kooperationen und freuen uns über Ihre Kontaktaufnahme!<br/>Bei Interesse oder Fragen erreichen Sie uns unter:<br/>
                            <a href="mailto:team@forestguardian.de" style={{ color: '#3498db', textDecoration: 'underline', textDecorationColor: '#3498db' }}>team@forestguardian.de</a>
                        </p>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </header>
    );
}

export default Header;
