/**
 * @fileOverview This file is part of a web application developed by Mattes Knigge.
 * @author Mattes Knigge
 * @version 1.0.0
 *
 * Description: This JavaScript file is responsible for implementing the core functionalities of the web application,
 * including event handling, data processing, and interacting with other web APIs.
 * It encapsulates the necessary logic to ensure smooth operation and user interaction within the application, contributing to the overall user experience and application performance.
 *
 * Created Date: 2024
 * Modified Date: 2024 - if applicable
 *
 * Contact Information:
 *   Name: Mattes Knigge
 *   Email: ma.knigge@ostfalia.de
 *
 * © 2024 Mattes Knigge.
 */

import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import '../styles/Home.css';

function Home() {
    const carouselSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        cssEase: "linear",
        swipeToSlide: true,
        adaptiveHeight: true,
        arrows: true,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
    };

    const imageRef = useRef(null);

    useEffect(() => {
        const currentImage = imageRef.current;
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('in-view');
                } else {
                    entry.target.classList.remove('in-view');
                }
            },
            {
                threshold: 0.8
            }
        );

        if (currentImage) {
            observer.observe(currentImage);
        }

        return () => {
            if (currentImage) {
                observer.unobserve(currentImage);
            }
        };
    }, []);

    return (
        <div className="home">
            <img ref={imageRef} src={"image_1.webp"} alt={"Forest Guardian"} className="responsive-image"/>
            <Slider {...carouselSettings}>
                <div className="carousel-item">
                    <h2>Unsere Verantwortung für die Natur</h2>
                    <p>Bei Forest Guardian setzen wir uns für den Schutz und die Pflege von Waldgebieten ein. Es ist unser Bestreben, Waldbesitzern dabei zu helfen, ihre Wälder sorgsam zu bewirtschaften und zu sichern.</p>
                </div>
                <div className="carousel-item">
                    <h2>Technik im Einklang mit der Umwelt</h2>
                    <p>Unsere Sensoren, die in Vogelhäusern verborgen sind, erfassen Umweltdaten, ohne die Tierwelt zu stören. Sie ermöglichen eine umsichtige Beobachtung und tragen zum Schutz des Ökosystems bei.</p>
                </div>
                <div className="carousel-item">
                    <h2>Unabhängigkeit durch Innovation</h2>
                    <p>Die Produkte von Forest Guardian sind so konzipiert, dass sie ohne externe Energiequellen auskommen. Sie sind wartungsarm und daher besonders geeignet für entlegene Waldregionen.</p>
                </div>
                <div className="carousel-item">
                    <h2>Engagieren Sie sich gemeinsam mit uns</h2>
                    <p>Unterstützen Sie unseren Einsatz für den Erhalt der Wälder. Lernen Sie unsere Produkte kennen und erfahren Sie, wie auch Sie zum Schutz unserer natürlichen Lebensgrundlagen beitragen können.</p>
                </div>
                <div className="carousel-item">
                    <h2>Wissen teilen und bewahren</h2>
                    <p>Wir teilen unser Wissen gerne und laden Interessierte ein, gemeinsam mit uns für eine nachhaltige Zukunft der Wälder zu arbeiten.</p>
                </div>
            </Slider>

            <div className="divider"></div>
            <div className="additional-text">
                <h2 className={"additional-h1"}>Projektinformationen</h2>
                <p>ForestGuardian startete als ein Projekt von Studierenden der Ostfalia Hochschule in Wolfenbüttel und wird nun kontinuierlich weiterentwickelt. Es beinhaltet ein intelligentes Vogelhaus mit Sensoren, um Waldbedingungen wie die Luftfeuchtigkeit und mehr zu überwachen. Die gesammelten Daten sind über eine benutzerfreundliche Webseite zugänglich.</p>
            </div>
            <div className="divider"></div>
            <div className="additional-text">
                <h2 className={"additional-h1"}>Unsere Vision</h2>
                <p>Bei Forest Guardian träumen wir von einer Welt, in der Mensch und Natur in harmonischer Koexistenz gedeihen. Unsere Mission ist es, durch innovative Technologie einen nachhaltigen und respektvollen Umgang mit unseren Waldressourcen zu fördern. Wir glauben fest daran, dass jeder Baum zählt und jede Handlung zur Erhaltung unseres Planeten beiträgt. Unsere Vision ist es, eine globale Gemeinschaft zu inspirieren und zu ermächtigen, die sich aktiv für den Schutz und die Wiederherstellung der Wälder einsetzt. Wir entwickeln Lösungen, die nicht nur die Gesundheit der Wälder überwachen, sondern auch die Menschen weltweit für deren Schutz sensibilisieren und mobilisieren. Unsere Arbeit basiert auf den Grundwerten der Nachhaltigkeit, Innovation und Gemeinschaft, mit dem Ziel, eine grünere, lebenswertere Welt für zukünftige Generationen zu gestalten.</p>
            </div>
            <img src={"future_forest.webp"} alt={"Forest Guardian"} className="responsive-image-2"/>
            <div className="divider"></div>
        </div>
    );
}

function SlickArrowLeft({ onClick }) {
    return (
        <button className="slick-prev" onClick={onClick}>
            &#10094; {/* Unicode left-pointing arrow */}
        </button>
    );
}

function SlickArrowRight({ onClick }) {
    return (
        <button className="slick-next" onClick={onClick}>
            &#10095; {/* Unicode right-pointing arrow */}
        </button>
    );
}

export default Home;
